import React from "react";

import './contact.css';
import twitter from '../../../images/social/twitter.png'
import facebook from '../../../images/social/facebook.png'
import instagram from '../../../images/social/instagram.png'
import mail from '../../../images/social/mail.png'

export const Contact = () => {
  return (
    <div className='contact-container'>
      <div className='contact-header'>Contact Us</div>
      <div className="contact-social-container">
        <a href="mailto:info.cinebears@gmail.com"><img src={mail} alt="mail" /></a>
        <a href='https://twitter.com/cinebearsclub'><img src={twitter} alt="twitter"/></a>
        <a href='https://www.facebook.com/cinebears.filmmaking.at.cal/'><img src={facebook} alt="facebook"/></a>
        <a href='https://www.instagram.com/cinebears/'><img src={instagram} alt="instagram"/></a>
      </div>
    </div>
  )
}