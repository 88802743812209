import React from "react";

import './nav.css'

const navItems = ['about', 'contact', 'photos'];
export const Nav = ({ currPg, onChange }) => {
  return (
    <div className='nav-container'>
      <div 
        className={`${currPg === 'home' ? 'active' : ''} nav-item`} 
        onClick={() => onChange('home')}
      > home </div>
      <div className='nav-items-right'>
        {
          navItems.map(itm => 
            <div 
              key={itm} 
              onClick={() => onChange(itm)}
              className={`${currPg === itm ? 'active' : ''} nav-item`}
            > { itm } </div>
          )
        }
      </div>
    </div>
  )
}