import React from "react";

import penguin from '../../images/ocf-hosted-penguin.svg'
import './footer.css'

export const Footer = ({ currPg, onChange }) => {
  return (
    <div className='footer-container'>
      <div>Hosted by The OCF <img src={penguin} alt="ofc"/> || Made by <a href="https://zkirby.com/">Zach Kirby</a> w/ love</div>
      {/* <div>Made by <a href="https://zkirby.com/">Zach Kirby</a> w/ love</div> */}
      <div>©Cinebears 2019. We are a student group acting independently of the University of California. We take full responsibility for our organization and this web site.</div>
    </div>
  )
}