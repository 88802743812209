import React from "react";

import './about.css'

export const About = () => {
  return (
    <div className='about-container'>
      Cinebears is a student organization committed to the 
      unification, longevity, and prosperity of the UC Berkeley 
      film community. Cinebears recognizes the immense talent 
      within the University's film community and seeks to 
      create a cohesive, creative, and resourceful space for 
      filmmakers to achieve their full potential. Cinebears is 
      also a place for Cinephiles! If you love film and want 
      to discuss film and be around others who appreciate cinema, 
      we would love to have you! (No need to have filmmaker 
      aspirations.) We facilitate discussions around film, 
      focus on collaboration, creativity, entertainment, and 
      community. Through the facilitation of screenings, community 
      socials, festivals, and speaker series, in addition to 
      ongoing collaboration with all active film organizations, 
      Cinebears aims to provide greater opportunities for 
      passionate filmmakers and Cal cinephiles. To be a Cal 
      filmmaker is to be a member of Cinebears, a title to be 
      held with pride as a badge of one's wholehearted dedication 
      to the betterment of UC Berkeley filmmaking.
    </div>
  )
}