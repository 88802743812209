import React from "react";

import './pictures.css'

const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../../../images/display_images', false, /\.(png|jpe?g|svg)$/));
images.sort();
export const Pictures = () => {
  return (
    <div className='pictures-container'>
      {images.map(img => {
        return (
          <img key={img} src={img} alt={`${img.slice(6)} cinebears`}/>
        )
      })}
    </div>
  )
}
