import React, { useState } from "react";

import './home.css'

export const Home = () => {
  // needed in order to have fade in AND fade out of bg picture
  const [currBg, setCurrBg] = useState('first-pic#hidden');
  const [bg, isVisible] = currBg.split('#');
 
  return (
    <div className='home-container'>
      <div 
        className={`home-overlay ${isVisible !== 'hidden' ? 'is-visible' : ''} ${bg}`} 
      />
      <div 
        className='home-text-container'
        onMouseLeave={() => setCurrBg(`${bg}#hidden`)}
      >
        <div 
          className={`${currBg === 'first-pic' ? 'active-pic': ''}`}
          onMouseEnter={() => setCurrBg('first-pic')}
        > Uniting The </div>
        <div
          className={`${currBg === 'second-pic' ? 'active-pic': ''}`}
          onMouseEnter={() => setCurrBg('second-pic')}
        >UC Berkeley</div>
        <div
          className={`${currBg === 'third-pic' ? 'active-pic': ''}`}
          onMouseEnter={() => setCurrBg('third-pic')}
        >Film Community</div>
      </div>
    </div>
  )
}