import React, { useState } from "react"

import { Nav } from '../components/nav/nav';
import { Footer } from '../components/footer/footer';
import { About } from '../components/content_pages/about/about';
import { Pictures } from '../components/content_pages/pictures/pictures';
import { Contact } from '../components/content_pages/contact/contact';
import { Home } from '../components/content_pages/home/home';

import './index.css'

/* 
If you're reading this, you're either bored enough to look at the shitty code of 
some random film club on campus or you are the person now responsible for maintaining this website.

In the case of the later, I wanted to clear up a few things. 

You might be asking yourself, looking at this gatsby-netlify-react app, was
all of this necessary? Would it not have been much much easier to use vanillaJS.
Yes, yes it would have. But I wanted to, so I did. That's how life works. 

You might be asking yourself, is it even necessary for a film club to have a website? Probably not.
But we did. That's how life works. 

I trust in you to not fuck up this website. It was made with love and overcomplicated 
development practices.
*/
const contentPages = {
  'home': <Home />,
  'about': <About />,
  'contact': <Contact />,
  'photos': <Pictures />,
}

const IndexPage = () => {
  const [currPg, setCurrPg] = useState('home');
  return (
    <div className="container">
      <Nav currPg={currPg} onChange={setCurrPg} />
      {
        contentPages[currPg] || 
        <div> Something went wrong :( </div>
      }
      <Footer />
    </div>
  )
}

export default IndexPage
